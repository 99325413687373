import React from "react"
import PropTypes from "prop-types"

import Page, { Footer, Header, HeaderContent } from "@src/components/Page"
import Column from "@src/components/Columns/Column"
import Columns from "@src/components/Columns/Columns"

import CommercialInsuranceNetLogoAd from "@src/components/CommercialInsuranceNetLogoAd"
import LoremIpsum from "@src/components/LoremIpsum"
import { ApplyUrlProvider } from "@src/hooks/useApplyUrl"

const CommercialInsuranceNetLogoAdPage = ({ location }) => {
  const title = "CommercialInsurance.net Logo Ad"
  const breadcrumb = {
    page: {
      title,
    },
    slug: "SMALL_BUSINESS",
  }
  return (
    <ApplyUrlProvider
      location={location}
      attributionTopic="Small Business Loans"
    >
      <Page
        title={title}
        header={<Header />}
        headerContent={<HeaderContent title={title} breadcrumb={breadcrumb} />}
        footer={<Footer />}
      >
        <Columns>
          <Column width={{ desktop: 8, mobile: 12 }}>
            <LoremIpsum />
            <LoremIpsum />
            <LoremIpsum />
            <LoremIpsum />
            <LoremIpsum />
            <LoremIpsum />

            <CommercialInsuranceNetLogoAd applyLink="https://www.nerdwallet.com/redirect/701754ee-fabb-11eb-aa9f-eb86bbd894bb?mvt=MTY1NDYwODcxOTQ0MDg1NjMxMjo0NTY4ODlmNDIxN2NiMzI0OTc2ZDBjMjkxMDM0YjY3MWJjY2IzNTdjZjczMzhiYjMtNjk5Yi00NGMzLWM0NzEtYTFjY2VkZWRiNzU4OmY4NDg4MTc0ZDg5MDFlMGQ4OGFjNmJhZWI2MjgyOWJhNjdkZGQ4ZTMwZjYzMGFmMzQ4ZmMzMGFiMjRhZDk0Zjk%3D" />

            <LoremIpsum />
            <LoremIpsum />
            <LoremIpsum />
            <LoremIpsum />
            <LoremIpsum />
            <LoremIpsum />
            <LoremIpsum />
            <LoremIpsum />
            <LoremIpsum />
            <LoremIpsum />
            <LoremIpsum />
            <LoremIpsum />
          </Column>
          <Column width={{ desktop: 4, mobile: 12 }}>[right rail]</Column>
        </Columns>
      </Page>
    </ApplyUrlProvider>
  )
}

CommercialInsuranceNetLogoAdPage.propTypes = {
  location: PropTypes.shape({}).isRequired,
}

export default CommercialInsuranceNetLogoAdPage
